import { PopCcontainer, PopupTitle } from '../Css/Pop';
import Modal from '../Modal';
import { Tit } from '../Titles';
import Button, { BtnClose } from '@/components/Button';
import VolunteerComplete from '@/page-blocks/involve/volunteer/VolunteerComplete';
import VolunteerModify from '@/page-blocks/involve/volunteer/VolunteerModify';
import VolunteerStep1, {
  VolunteerStep1Data,
} from '@/page-blocks/involve/volunteer/VolunteerStep1';
import React, { FC, useState } from 'react';
import styled, { css } from 'styled-components';

export interface ModalProps {
  isOpen?: boolean;
  // 모달 바깥쪽 누를시 닫을수 있는지 여부
  dismissable?: boolean;
  // 닫힘요청시 콜백
  onRequestClose: () => void;
  maxWidth?: string;
}

const StepBody = styled.div`
  display: none;
  .btn-wrap {
    margin-top: 96px;
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }
`;
const ToggleBoxActive = css`
  form {
    display: block;
  }
  ${StepBody} {
    display: block;
  }
`;
const ToggleBox = styled.div<{ active: boolean }>`
  form {
    display: none;
  }
  ${StepBody} {
    display: none;
  }
  ${({ active }) => active && ToggleBoxActive};
`;

const PopContent = styled.div`
  padding-bottom: 32px;
  .m-main {
    width: 100%;
    max-width: 576px;
    margin: 0 auto;
  }
`;

const VolunteerLayerPopup: FC = ({ onRequestClose, ...props }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [step1Data, setStep1Data] = useState<VolunteerStep1Data>();

  return (
    <Modal onRequestClose={onRequestClose} maxWidth="880px" {...props}>
      <PopCcontainer>
        <PopupTitle>
          <h2>
            <Tit
              size="s3"
              color="sky"
              dangerouslySetInnerHTML={{ __html: props.PopTitle }}
            />
          </h2>
          <BtnClose onClick={() => onRequestClose()}>
            <span className="a11y">닫기</span>
          </BtnClose>
        </PopupTitle>
        <PopContent>
          {props.PopSid === `3` ? (
            <ToggleBox active={currentStep === 1}>
              <div className="m-main">
                <StepBody>
                  <VolunteerModify
                    sid={props.PopSid}
                    idx={props.idx}
                    filePath={props.filePath}
                    onSubmit={(data) => {
                      setStep1Data(data);
                      setCurrentStep(2);
                    }}
                  />
                </StepBody>
              </div>
            </ToggleBox>
          ) : (
            <ToggleBox active={currentStep === 1}>
              <div className="m-main">
                <StepBody>
                  <VolunteerStep1
                    sid={props.PopSid}
                    idx={props.idx}
                    filePath={props.filePath}
                    onSubmit={(data) => {
                      setStep1Data(data);
                      setCurrentStep(2);
                    }}
                  />
                </StepBody>
              </div>
            </ToggleBox>
          )}
          <ToggleBox active={currentStep === 2}>
            <StepBody>
              <VolunteerComplete
                sid={props.PopSid}
                onSubmit={() => onRequestClose()}
              />
              <div className="btn-wrap">
                <Button
                  size="sm"
                  type="submit"
                  onClick={() => {
                    onRequestClose();
                    setCurrentStep(1);
                  }}
                >
                  확인
                </Button>
              </div>
            </StepBody>
          </ToggleBox>
        </PopContent>
      </PopCcontainer>
    </Modal>
  );
};

export default VolunteerLayerPopup;
