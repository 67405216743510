import { Tit } from '@/components/Titles';
import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

interface VolunteerCompleteProps {
  sid: string;
  onSubmit: () => void;
}

const StepContainer = styled.div`
  padding-top: 129px;

  .btn-wrap {
    margin-top: 96px;
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }
`;

const VolunteerComplete: FC<VolunteerCompleteProps> = ({ sid }) => {
  const { handleSubmit } = useForm();
  return (
    <StepContainer>
      <form>
        <Tit size="s1-5" color="sky" weight="normal">
          {sid === `2` ? `캠페이너스` : `유챔프(U Champ)`}
          <br />
          {sid === `3`
            ? `지원정보 수정이 완료되었습니다.`
            : `지원이 완료되었습니다.`}
        </Tit>
      </form>
    </StepContainer>
  );
};
export default VolunteerComplete;
