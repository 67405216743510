import { AttFileControllerService } from '@/__generated__/CommonApi';
import { VolunteerControllerService } from '@/__generated__/FrontApi';
import Attachment from '@/components/Attachment';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import LabelText from '@/components/Form/LabelText';
import InputRadio from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import InputTextarea from '@/components/Input/InputTextarea';
import { breakpoint } from '@/helpers/BreakpointHelper';
import {
  birthRegex,
  emailRegex,
  mobileRegex,
} from '@/helpers/ValidationHelper';
import LocalStorage from '@/services/LocalStorage';
import { usePopupStore } from '@/stores/PopupStore';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import React, { FC, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

interface VolunteerCompleteProps {
  sid: string;
}

const StepContainer = styled.div`
  padding-top: 32px;
  ${FormGroup} {
    &.flex {
      margin-bottom: 24px;
      align-items: flex-start;
      .col {
        width: 50%;
      }
    }
    button {
      width: 100%;
      max-width: 320px;
    }
  }
  .col-flex {
    margin: 0;

    label {
      padding: 0;
      width: 50%;
      min-width: 0;
    }
    .btn {
      height: 56px !important;
      line-height: 54px !important;
    }
  }

  .row-flex {
    .col {
      width: 50%;
    }
  }
  .btn-wrap {
    margin-top: 126px;
    text-align: center;

    button {
      width: 100%;
      max-width: 256px;
    }
  }

  .agree-dl {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      margin-left: 32px;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  ${InputTextarea} {
    height: 160px;
  }
  ${breakpoint(`mobile`)} {
    .btn-wrap {
      margin-top: 70px;
    }

    .agree-dl {
      flex-wrap: wrap;
      justify-content: flex-start;

      dt,
      dd {
        width: 100%;
      }
      dd {
        margin-top: 6px;
      }
    }
  }
`;

export interface VolunteerStep1Data {
  type: string;
  volunteerIndexNumber: string;
  applyBirth: string;
  applyGender: string;
  applyUserEmail: string;
  applyAttGrpNo?: string;
  applyUserName: string;
  applyUserPhone: string;
  grade?: string;
  isAgreeYn: string;
  schoolMajor?: string;
  schoolName: string;
  schoolType?: string;
  selfContents?: string;
  fileAttach?: string;
}

interface VolunteerCompleteProps {
  onSubmit: (data: VolunteerStep1Data) => void;
  data?: any;
  idx: string;
  sid: string;
  filePath?: string;
}
const VolunteerStep1: FC<VolunteerCompleteProps> = ({
  sid,
  idx,
  filePath,
  onSubmit,
  ...props
}) => {
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<VolunteerStep1Data>({
    defaultValues: {
      type: ``,
      volunteerIndexNumber: ``,
      applyBirth: ``,
      applyGender: ``,
      applyUserEmail: ``,
      applyUserName: ``,
      applyUserPhone: ``,
      grade: ``,
      isAgreeYn: ``,
      schoolMajor: ``,
      schoolName: ``,
      schoolType: ``,
      selfContents: ``,
    },
  });
  // 첨부파일
  const [files, setFiles] = useState<File[]>([]);
  const [fileState, setFileState] = useState<boolean>();
  const popupStore = usePopupStore();

  const onSubmitForm: SubmitHandler<VolunteerStep1Data> = useCallback(
    async ({
      type,
      volunteerIndexNumber,
      applyBirth,
      applyGender,
      applyUserEmail,
      applyUserName,
      applyUserPhone,
      grade,
      isAgreeYn,
      schoolMajor,
      schoolName,
      schoolType,
      selfContents,
      ...formData
    }) => {
      try {
        let applyAttGrpNo: any;
        // 파일이 있다면
        if (files.length > 0) {
          const result = await AttFileControllerService.uploadUsingPost({
            file: files,
          });
          applyAttGrpNo = result.data.attGrpNo;
        }
        const {
          resultCode,
          resultMessage,
        } = await VolunteerControllerService.insertVolunteerApplyUsingPost(
          {
            type,
            volunteerIndexNumber,
            applyAttGrpNo,
            applyBirth,
            applyGender,
            applyUserEmail,
            applyUserName,
            applyUserPhone,
            grade,
            isAgreeYn,
            schoolMajor,
            schoolName,
            schoolType,
            selfContents,
            ...formData,
          },
          {
            headers: {
              'X-AUTH-TOKEN': LocalStorage.getItem(`token`),
            },
          },
        );
        // 성공후 폼 리셋
        onSubmit(applyAttGrpNo);
        reset();
        setFiles([]);
        setFileState(false);
      } catch (e) {
        console.error(e);
        popupStore.show(`자원봉사 신청에 실패했습니다. 다시 시도해주세요.`);
      }
    },
    [files, reset],
  );

  return (
    <StepContainer>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <div className="form-container">
          <input type="hidden" value={sid} ref={register()} name="type" />
          <input
            type="hidden"
            value={idx}
            ref={register()}
            name="volunteerIndexNumber"
          />
          <FormGroup className="FormGroup flex">
            <div className="col">
              <LabelText require>성명</LabelText>
              <InputText
                placeholder="성명 입력"
                name="applyUserName"
                ref={register({
                  required: {
                    value: true,
                    message: `성명을 입력해주세요.`,
                  },
                  maxLength: {
                    value: 20,
                    message: `20자 이내로 입력해주세요.`,
                  },
                })}
              />
              {errors.applyUserName && (
                <ErrorMessage>{errors.applyUserName.message}</ErrorMessage>
              )}
            </div>
            <div className="col">
              <LabelText require>성별</LabelText>
              <div className="col-flex flex">
                <InputRadio
                  name="applyGender"
                  tab
                  value="남"
                  label="남"
                  defaultChecked
                  ref={register({
                    required: {
                      value: true,
                      message: `성별을 선택해주세요.`,
                    },
                  })}
                />
                <InputRadio
                  name="applyGender"
                  tab
                  value="여"
                  label="여"
                  ref={register({
                    required: {
                      value: true,
                      message: `성별을 선택해주세요.`,
                    },
                  })}
                />
              </div>
              {errors.applyGender && (
                <ErrorMessage>{errors.applyGender.message}</ErrorMessage>
              )}
            </div>
          </FormGroup>

          <FormGroup className="FormGroup">
            <LabelText require>생년월일</LabelText>
            <InputText
              placeholder="생년월일 6자리 입력"
              name="applyBirth"
              type="number"
              ref={register({
                required: {
                  value: true,
                  message: `생년월일을 입력해주세요.`,
                },
                pattern: {
                  value: birthRegex,
                  message: `6자리 숫자로만 입력해주세요.`,
                },
                validate: (value) =>
                  dayjs(value, `YYMMDD`, true).isValid() ||
                  `올바르지 않은 형식입니다`,
              })}
            />
            {errors.applyBirth && (
              <ErrorMessage>{errors.applyBirth.message}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup className="FormGroup">
            <LabelText require>휴대폰번호</LabelText>
            <InputText
              placeholder="휴대폰번호 입력 ( &lsquo;-&rsquo; 제외 )"
              name="applyUserPhone"
              type="number"
              ref={register({
                required: {
                  value: true,
                  message: `휴대폰번호를 입력해주세요.`,
                },
                pattern: {
                  value: mobileRegex,
                  message: `올바른 휴대폰번호가 아닙니다.`,
                },
                maxLength: {
                  value: 15,
                  message: `15자 이내로 입력해주세요.`,
                },
                validate: {
                  isValidRange: (value) =>
                    (value.substr(0, 3) === `010` && value.length === 11) ||
                    (value.substr(0, 3) !== `010` && value.length === 10) ||
                    `휴대폰번호 자릿수를 확인해주세요`,
                },
              })}
            />
            {errors.applyUserPhone && (
              <ErrorMessage>{errors.applyUserPhone.message}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup className="FormGroup">
            <LabelText require>이메일</LabelText>
            <InputText
              placeholder="이메일주소 입력"
              name="applyUserEmail"
              ref={register({
                required: {
                  value: true,
                  message: `이메일주소를 입력해주세요.`,
                },
                maxLength: {
                  value: 50,
                  message: `50자 이내로 입력해주세요.`,
                },
                pattern: {
                  value: emailRegex,
                  message: `올바르지 않은 이메일주소 입니다.`,
                },
              })}
            />
            {errors.applyUserEmail && (
              <ErrorMessage>{errors.applyUserEmail.message}</ErrorMessage>
            )}
          </FormGroup>
          <FormGroup className="FormGroup flex">
            <div className="col">
              <LabelText require>학교명</LabelText>
              <InputText
                placeholder="학교명 입력"
                name="schoolName"
                ref={register({
                  required: {
                    value: true,
                    message: `학교명을 입력해주세요.`,
                  },
                  maxLength: {
                    value: 20,
                    message: `20자 이내로 입력해주세요.`,
                  },
                })}
              />
              {errors.schoolName && (
                <ErrorMessage>{errors.schoolName.message}</ErrorMessage>
              )}
            </div>
            <div className="col">
              <LabelText require>전공</LabelText>
              <InputText
                placeholder="전공 입력"
                name="schoolMajor"
                ref={register({
                  maxLength: {
                    value: 20,
                    message: `20자 이내로 입력해주세요.`,
                  },
                })}
              />
              {errors.schoolMajor && (
                <ErrorMessage>{errors.schoolMajor.message}</ErrorMessage>
              )}
            </div>
          </FormGroup>
          {sid === `1` && ( // 유챔프
            <FormGroup className="FormGroup">
              <LabelText require>지원서</LabelText>
              <Attachment
                accept="application/pdf"
                files={files}
                name="fileAttach"
                onFilesChange={setFiles}
              />
              {errors.fileAttach && (
                <ErrorMessage>지원서를 첨부해주세요.</ErrorMessage>
              )}
              <Button
                outline
                full
                ico="down"
                className="btn-down"
                onClick={() => navigate(filePath)}
              >
                <span className="ico">지원서 다운로드</span>
              </Button>
            </FormGroup>
          )}
          {sid === `2` && ( // 캠페이너스
            <div>
              <FormGroup className="FormGroup">
                <LabelText require>학년</LabelText>
                <div className="row-flex flex">
                  <div className="col">
                    <InputText
                      type="number"
                      placeholder="학년 입력"
                      name="grade"
                      ref={register({
                        maxLength: {
                          value: 2,
                          message: `2자 이내 숫자로만 입력해주세요.`,
                        },
                      })}
                    />
                    {errors.grade && (
                      <ErrorMessage>{errors.grade.message}</ErrorMessage>
                    )}
                  </div>
                  <div className="col">
                    <div className="col-flex flex">
                      <InputRadio
                        name="schoolType"
                        tab
                        label="재학"
                        value="재학"
                        ref={register}
                      />
                      <InputRadio
                        name="schoolType"
                        tab
                        label="휴학"
                        value="휴학"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>

              <FormGroup className="FormGroup">
                <LabelText require>자기소개</LabelText>
                <InputTextarea
                  placeholder="지원동기, 관련 경험을 2,000자 이내로 작성하세요."
                  name="selfContents"
                  ref={register({
                    maxLength: {
                      value: 2000,
                      message: `2000자 이내로 입력해주세요.`,
                    },
                  })}
                />
                {errors.selfContents && (
                  <ErrorMessage>{errors.selfContents.message}</ErrorMessage>
                )}
              </FormGroup>
            </div>
          )}
        </div>
        <strong className="tip">
          [필수] 유니세프한국위원회는 자원봉사 안내, 확인 및 증명서 발급
          목적으로 성명, 생년월일, 휴대폰번호, 이메일, 소속 정보를 수집하며,
          동의 철회 시 까지 이용·보관합니다. 동의를 거부하는 경우, 자원봉사
          참여가 불가합니다.
        </strong>
        <dl className="agree-dl">
          <dt>개인정보 수집·이용에 동의하십니까?</dt>
          <dd>
            <InputRadio
              label="동의함"
              name="isAgreeYn"
              value="Y"
              ref={register({
                required: {
                  value: true,
                  message: `개인정보 수집·이용에 동의해주세요.`,
                },
              })}
            />
            <InputRadio label="동의하지 않음" name="isAgreeYn" value="N" />
          </dd>
        </dl>
        {errors.isAgreeYn && (
          <ErrorMessage>{errors.isAgreeYn.message}</ErrorMessage>
        )}
        <div className="btn-wrap">
          <Button size="sm" type="submit">
            지원하기
          </Button>
        </div>
      </form>
    </StepContainer>
  );
};
export default VolunteerStep1;
