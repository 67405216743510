/* eslint-disable no-nested-ternary */
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import {
  VolunteerControllerService,
  VolunteerVo,
} from '@/__generated__/FrontApi';
import Profile1 from '@/assets/img/involve/volunteer_profile1@2x.png';
import Profile2 from '@/assets/img/involve/volunteer_profile2@2x.png';
import Profile3 from '@/assets/img/involve/volunteer_profile3@2x.png';
import SnsBlog from '@/assets/img/tmp/involve/sns_blog@2x.png';
import SnsFacebook from '@/assets/img/tmp/involve/sns_facebook@2x.png';
import SnsInsta from '@/assets/img/tmp/involve/sns_insta@2x.png';
import ApplyHeader from '@/assets/img/tmp/involve/volunteer_apply_bg@2x.png';
import TopImg2 from '@/assets/img/tmp/involve/volunteer_top_img2@2x.jpg';
import TopImg from '@/assets/img/tmp/involve/volunteer_top_img@2x.png';
import Button from '@/components/Button';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ReviewCard from '@/components/Card/ReviewCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import VolunteerLayerPopup from '@/components/Layer/VolunteerLayerPopup';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

const items = [
  {
    id: 1,
    dec: `“유챔이들과 함께 아동권리를 공부하고 <br />개선 아이디어를 짜내는 의미있는 시간이었습니다.” <small>황준아 유챔이(활동기획팀)</small>`,
    pcImage: Profile1,
    mobileImage: Profile1,
  },
  {
    id: 2,
    dec: `“아동, 그리고 더 나은 세상이라는 지향점을 공유하며 <br />함께 선한 영향력을 만드는 유챔프 활동에 자부심을 느낍니다!” <small>공혜린 유챔이(활동기획팀)</small>`,
    pcImage: Profile2,
    mobileImage: Profile2,
  },
  {
    id: 3,
    dec: `“유챔프 활동을 통해 유엔아동권리협약에 대해 깊이 공부하고, <br />주변에 아동권리의 중요성을 알릴 수 있었습니다.” <small>김윤진 유챔이(교육팀)</small>`,
    pcImage: Profile3,
    mobileImage: Profile3,
  },
];

const contactData1 = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리교육팀`,
    tel: `02-724-8573`,
    email: `volunteer@unicef.or.kr`,
  },
];
const contactData2 = [
  {
    id: 1,
    tit: `문의`,
    costumer: `아동권리교육팀`,
    tel: `02-724-8574`,
    email: `village@unicef.or.kr`,
  },
];

const Rivew = styled.div`
  margin-top: 140px;

  .swiper-container {
    padding-bottom: 0;
    overflow: hidden;

    .swiper-slide {
      width: 100%;
      background: #f8f9fa;
      padding: 48px 96px;
      margin: 0;
    }
    .swiper-scrollbar {
      display: none;
    }

    .swiper-button-next,
    .swiper-button-prev {
      bottom: 32px;
      z-index: 10;
      margin: 0;
    }
    .swiper-button-next {
      right: 65px;
    }
    .swiper-button-prev {
      right: 144px;
    }
  }

  .CardLayout {
    display: flex;
    align-items: center;

    .img-wrap {
      width: 160px;
    }
    .dec-wrap {
      width: calc(100% - 160px);
      padding-left: 65px;

      .dec {
        margin-top: 0;
      }
    }
  }

  ${breakpoint(1280)} {
    .swiper-container {
      .swiper-slide {
        padding: 48px 7.5vw;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .swiper-container {
      padding-bottom: 90px !important;

      .swiper-slide {
        padding: 32px 24px;
      }

      .swiper-button-next,
      .swiper-button-prev {
        display: block;
        z-index: 10;
        bottom: 10px;
      }
      .swiper-button-next {
        right: 10px;
      }
      .swiper-button-prev {
        right: 90px;
      }
    }
  }
  ${breakpoint(`mobile`)} {
    width: calc(100% + 40px);
    position: relative;
    left: -20px;
    .swiper-container {
      .swiper-slide {
        padding-bottom: 49px;
      }
    }
    .CardLayout {
      flex-wrap: wrap;
      .img-wrap,
      .dec-wrap {
        width: 100%;
        padding: 0;
      }
      .img-wrap {
        text-align: center;

        img {
          max-width: 160px;
        }
      }
      .dec-wrap {
        margin-top: 32px;
        br {
          display: none;
        }
      }
    }
  }
`;
const Apply = styled.div`
  header {
    padding: 72px;
    padding-left: 95px;
    background: url(${ApplyHeader}) center right no-repeat;

    ${Tit} {
      line-height: 1.5;
    }
  }
  ${breakpoint(800)} {
    header {
      padding: 44px 24px;
    }
  }

  .apply-content {
    border: 1px solid #1cabe2;
    padding: 96px 20px;

    .editor-container {
      margin: 0 auto;
      width: 736px;
    }

    .btn-flex {
      width: 736px;
      margin: 24px auto 0;
      justify-content: space-between;
      & > li {
        width: 48%;
        padding: 0;
        button {
          width: 100%;
        }
      }
    }

    .btn-wrap {
      margin-top: 96px;
      text-align: center;
      width: 544px;
      button {
        width: 100%;
        max-width: 350px;
      }
    }
  }

  ${breakpoint(800)} {
    margin: 0 -20px;

    .apply-content {
      padding: 70px 20px;
      border-left: none;
      border-right: none;
      .btn-flex {
        width: 100%;
      }
      .editor-container {
        width: 100%;
      }
    }
  }

  ${breakpoint(`mobile`)} {
    header {
      background-size: auto 100%;
      padding: 61px 20px;
    }
    .apply-content {
      .btn-flex {
        flex-wrap: wrap;
        margin-top: 16px;

        & > li {
          width: 100%;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .btn-wrap {
        margin-top: 72px;
        button {
          max-width: 100%;
        }
      }
    }
  }
`;
const DlContainer = styled.div`
  dl {
    display: flex;
    align-items: flex-start;
    padding: 32px 0;
    border-bottom: 1px solid #f8f9fa;

    &:first-child {
      padding-top: 0;
    }

    dt {
      width: 34%;

      ${Tit} {
        position: relative;
        padding-left: 25px;

        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: #1cabe2;
          position: absolute;
          left: 0;
          top: 15px;
        }

        strong {
          color: #1cabe2;
        }
      }
    }
    dd {
      width: 66%;
      line-height: 1.7;

      .blit-list {
        li {
          &::before {
            background: #2d2926;
          }
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    dl {
      dt {
        ${Tit} {
          &::before {
            top: 11px;
          }
        }
      }
      dd {
        padding-left: 12px;

        .blit-list {
          margin-left: -12px;
        }
      }
    }
  }
  ${breakpoint(`mobile`)} {
    dl {
      flex-wrap: wrap;

      dt,
      dd {
        width: 100%;
      }
      dd {
        margin-top: 16px;
      }
    }
  }
`;

const Content = styled.div`
  position: relative;

  .flex-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 100px;

    &::before {
      content: '';
      width: 1280px;
      height: 100%;
      background: #e2f0f6;
      border-radius: 0 0 0 100px;
      position: absolute;
      left: 240px;
      top: 0;
      z-index: -1;
    }

    .col-img {
      width: 48.6%;
    }

    .col-dec {
      padding-left: 32px;
      width: 51.4%;
      max-width: 480px;

      dt {
        border-bottom: 1px solid #1cabe2;
        padding-bottom: 8px;

        ${Tit} {
          line-height: 1.6;
        }
      }

      dd {
        padding-top: 32px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .flex-container {
      flex-wrap: wrap;
      margin-left: -20px;
      margin-right: -20px;
      padding-bottom: 0;

      &::before {
        display: none;
      }

      .col-img,
      .col-dec {
        width: 100%;
      }

      .col-dec {
        padding-top: 24px;
        padding-left: 20px;
        padding-bottom: 91px;
        max-width: 100%;
        background: #e2f0f6;

        dd {
          padding-top: 24px;
        }
      }
    }
  }
`;

const SectionTop = styled(Section)`
  .flex-container {
    padding: 32px 0;
    margin-bottom: 240px;

    .sns-flex {
      margin: 0;
      margin-top: 70px;
      justify-content: center;

      & > li {
        width: 80px;
        padding: 0;
        margin: 0 16px;
      }
    }
  }
  .tip {
    font-size: 14px;
    color: #e2231a;
    ${breakpoint(640)} {
      font-size: 12px;
    }
  }

  ${Apply} {
    margin-top: 240px;
    margin-bottom: 96px;
  }

  ${breakpoint(`tablet`)} {
    ${Content},
    .flex-container {
      padding-top: 0;
    }
    .flex-container {
      padding-bottom: 0;
      margin-bottom: 120px;

      .col-img {
        padding: 0 20px;
        margin-bottom: 25px;
      }

      .sns-flex {
        justify-content: flex-end;
        margin-top: 56px;
        padding-right: 8px;

        & > li {
          width: 40px;
          margin: 0 12px;
        }
      }

      .col-dec {
        padding-bottom: 23px;
      }
    }

    ${Apply} {
      margin-top: 120px;
      margin-bottom: 70px;
    }
  }
`;
const ApplyButton = styled.div`
  width: 320px;
  margin: 0 auto 96px;
  ${Button} {
    width: 100%;
  }
`;
const SectionCampaigners = styled(Section)`
  .flex-container {
    margin-bottom: 240px;
    .dec.blue {
      color: #1cabe2;
    }
  }
  .tip {
    font-size: 14px;
    color: #e2231a;
    ${breakpoint(640)} {
      font-size: 12px;
    }
  }

  ${DlContainer} {
    Button {
      margin-top: 16px;
      width: 100%;
      max-width: 320px;
    }
  }
  ${Apply} {
    margin-top: 240px;
    margin-bottom: 96px;
  }

  ${breakpoint(`tablet`)} {
    .flex-container {
      margin-bottom: 120px;
    }

    ${Apply} {
      margin-top: 72px;
      margin-bottom: 96px;
    }
  }

  ${breakpoint(`mobile`)} {
    ${DlContainer} {
      Button {
        max-width: 100%;
      }
    }
  }
`;

const Volunteer: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A016'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  const userStore = useUserStore();
  const popupStore = usePopupStore();
  const [popupOpen, setPopupOpen] = useState(false);
  const [PopupTitle, setPopupTitle] = useState(``);
  const [PopupSid, setPopupSid] = useState(``);

  const open = () => setPopupOpen(true);
  const close = () => setPopupOpen(false);

  const [uchamp, setUchamp] = useState<VolunteerVo>();
  const [campaigners, setCampaigners] = useState<VolunteerVo>();
  const [idx, setIdx] = useState(``);
  const [filePath, setFilePath] = useState(``);

  // 모집공고 지원 상태 조회 state
  const [userStatus, setUserStatus] = useState([]);

  useEffect(() => {
    VolunteerControllerService.getVolunteerNofiticationUsingGet({
      type: `1`,
    })
      .then(({ data }) => {
        setUchamp(data);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    VolunteerControllerService.getVolunteerNofiticationUsingGet({
      type: `2`,
    })
      .then(({ data }) => {
        setCampaigners(data);
      })
      .catch((e) => console.error(e));
  }, []);

  useEffect(() => {
    // 로그인된 상태면 로드
    if (userStore.isLoggedIn()) {
      VolunteerControllerService.getVoluteerApplyStatusUsingGet()
        .then(({ data }) => {
          setUserStatus(data);
        })
        .catch((e) => console.error(e));
    }
  }, [userStore]);

  return (
    <LayoutWithTitle
      location={location}
      title="자원봉사"
      description="for every child, volunteers"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이가 <br />
                  행복한 세상을 만들기 위해
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                유니세프의 뜻에 공감하는 자원봉사자들이 자신의 재능과 시간을
                나누며
                <br className="small-hide" />
                유니세프한국위원회와 함께 다양한 활동을 하고 있습니다.
              </PageComponent>
            </p>
          </SectionHeader>

          <Content>
            <div className="flex-container">
              <div className="col-img">
                <Image pcSrc={TopImg} mobileSrc={TopImg} />
              </div>
              <dl className="col-dec">
                <dt>
                  <p className="dec">U Champ</p>
                  <Tit size="s3-1" color="sky">
                    유니세프 아동권리 서포터즈 유챔프
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    모든 어린이가 행복한 세상을 위해 행동하는 유니세프한국위원회
                    대학생 자원봉사단입니다.
                  </p>
                  <ul className="sns-flex flex">
                    <li>
                      <LinkSafe
                        to="https://www.facebook.com/unicefchampions"
                        target="_blank"
                      >
                        <Image pcSrc={SnsFacebook} mobileSrc={SnsFacebook} />
                      </LinkSafe>
                    </li>
                    <li>
                      <LinkSafe
                        to="https://www.instagram.com/uchamp_korea/"
                        target="_blank"
                      >
                        <Image pcSrc={SnsInsta} mobileSrc={SnsInsta} />
                      </LinkSafe>
                    </li>
                    <li>
                      <LinkSafe
                        to="https://blog.naver.com/u_champ"
                        target="_blank"
                      >
                        <Image pcSrc={SnsBlog} mobileSrc={SnsBlog} />
                      </LinkSafe>
                    </li>
                  </ul>
                </dd>
              </dl>
            </div>
          </Content>

          <DlContainer>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>누가</strong> 할 수 있나요?
                </Tit>
              </dt>
              <dd>
                <strong>
                  1년 간 지속적으로 활동이 가능한 대학(원) 재학생 및 휴학생
                </strong>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>어떤 활동</strong>을 하나요?
                </Tit>
              </dt>
              <dd>
                교육팀, 미디어홍보팀, 활동기획팀으로 구성
                <ul
                  className="blit-list"
                  css={`
                    margin-top: 16px;
                  `}
                >
                  <li>유니세프한국위원회 업무지원</li>
                  <li>유니세프한국위원회 홍보부스 및 캠페인 지원</li>
                  <li>아동권리 옹호 활동 기획 및 실행</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>언제, 어떻게</strong> 지원할 수 있나요?
                </Tit>
              </dt>
              <dd>
                연 1회 웹사이트를 통해 모집
                <p className="tip">
                  *해외 자원봉사 프로그램은 운영하지 않습니다.
                </p>
              </dd>
            </dl>
          </DlContainer>

          <Rivew>
            <ReviewCard campaigns={items} img />
          </Rivew>
          <Rivew />

          {uchamp && (
            <>
              <Apply>
                <header>
                  <Tit size="s2" color="white">
                    {uchamp.title}
                  </Tit>
                </header>
                <div className="apply-content">
                  <div
                    className="editor-container"
                    dangerouslySetInnerHTML={{ __html: uchamp.contents }}
                  />
                  <ul className="btn-flex flex">
                    <li>
                      <Button
                        ico="down"
                        outline
                        // TODO: 백엔드 확인 필요. 임시 수정
                        onClick={() => navigate(uchamp.attGrpNo)}
                      >
                        <span className="ico">지원서 다운로드</span>
                      </Button>
                    </li>
                    <li>
                      <Button
                        ico="more"
                        outline
                        color="gray"
                        onClick={() =>
                          window.open(`https://blog.naver.com/u_champ`)
                        }
                      >
                        <span className="ico">유챔프 블로그</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </Apply>
              <ApplyButton>
                {userStore.isLoggedIn() ? (
                  userStatus.uchampApplyCount !== 0 ? (
                    <Button
                      onClick={() => {
                        open();
                        setPopupTitle(`유챔프(U Champ) 지원정보 수정하기`);
                        setPopupSid(`3`);
                        setIdx(uchamp.volunteerIndexNumber);
                        setFilePath(uchamp.attGrpNo);
                      }}
                    >
                      지원정보 수정
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        open();
                        setPopupTitle(`유챔프(U Champ) 지원하기`);
                        setPopupSid(`1`);
                        setIdx(uchamp.volunteerIndexNumber);
                        setFilePath(uchamp.attGrpNo);
                      }}
                    >
                      유챔프 지원하기
                    </Button>
                  )
                ) : (
                  <Button
                    onClick={() => {
                      popupStore.show(`로그인 후에 지원 가능합니다.`, {
                        onConfirm: () =>
                          navigate(`/auth/login?redirectTo=/involve/volunteer`),
                      });
                    }}
                  >
                    유챔프 지원하기
                  </Button>
                )}
              </ApplyButton>
            </>
          )}

          <ContactUs itemData={contactData1} />
        </Container>
      </SectionTop>

      <SectionCampaigners className="by-sub-main-layout">
        <Container>
          <Content>
            <div className="flex-container">
              <div className="col-img">
                <Image pcSrc={TopImg2} mobileSrc={TopImg2} />
              </div>
              <dl className="col-dec">
                <dt>
                  <p className="dec blue">UNICEF Campaigners</p>
                  <Tit size="s3-1" color="sky">
                    유니세프 캠페이너스
                  </Tit>
                </dt>
                <dd>
                  <p className="dec">
                    유니세프 어린이지구촌체험관에 방문하는 어린이들
                    <br />
                    이 유니세프의 다양한 활동을 경험하고 세계시민의
                    <br />
                    꿈을 키울 수 있도록 돕습니다.
                  </p>
                </dd>
              </dl>
            </div>
          </Content>

          <DlContainer>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>누가</strong> 할 수 있나요?
                </Tit>
              </dt>
              <dd>
                9개월 간 지속적으로 활동 가능한
                <br />만 19세 이상 만 30세 미만의 대학(원)생 및 휴학생
                <p className="tip">
                  *월 1회 이상, 총 15회 이상 활동 시 수료 가능
                  <br />
                  *본인 의사에 따라 기간 연장 가능
                </p>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>어떤 활동</strong>을 하나요?
                </Tit>
              </dt>
              <dd>
                생존팀, 보호팀, 발달팀, 참여팀으로 구성
                <ul
                  className="blit-list"
                  css={`
                    margin-top: 16px;
                  `}
                >
                  <li>체험 코너 안내 및 체험관 운영</li>
                  <li>체험관 컨텐츠 및 프로그램 기획·홍보</li>
                </ul>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>어디서</strong> 하나요?
                </Tit>
              </dt>
              <dd>
                유니세프빌딩 1층 유니세프 어린이지구촌체험관
                <br />
                <Button
                  color="gray"
                  outline
                  ico="more"
                  onClick={() => navigate(`/location`)}
                >
                  <span className="ico">오시는 길</span>
                </Button>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1">
                  <strong>언제, 어떻게</strong> 지원할 수 있나요?
                </Tit>
              </dt>
              <dd>연 1~2회 웹사이트를 통해 모집</dd>
            </dl>
          </DlContainer>

          <Rivew />

          {campaigners && (
            <>
              <Apply>
                <header>
                  <Tit size="s2" color="white">
                    {campaigners.title}
                  </Tit>
                </header>
                <div className="apply-content">
                  <div
                    className="editor-container"
                    dangerouslySetInnerHTML={{ __html: campaigners.contents }}
                  />
                </div>
              </Apply>
              <ApplyButton>
                <Button
                  onClick={() => {
                    open();
                    setPopupTitle(`캠페이너스 지원하기`);
                    setPopupSid(`2`);
                    setIdx(campaigners.volunteerIndexNumber);
                  }}
                >
                  캠페이너스 지원하기
                </Button>
              </ApplyButton>
            </>
          )}

          <ContactUs itemData={contactData2} />
        </Container>
      </SectionCampaigners>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A016" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>

      <VolunteerLayerPopup
        isOpen={popupOpen}
        PopTitle={PopupTitle}
        PopSid={PopupSid}
        onRequestClose={close}
        idx={idx}
        filePath={filePath}
      />
    </LayoutWithTitle>
  );
};

export default Volunteer;
